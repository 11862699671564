const liveStreamPreview = () => {
  document.getElementById('livestreams').addEventListener('click', (e) => {
    const clickedEl = e.target;
    const previewLink = clickedEl.closest('.articles-item');
    if (previewLink) {
      e.preventDefault();
      const url = previewLink.href;
      window.open(
        url,
        '_blank',
        'location=yes,height=780,width=700,scrollbars=yes,status=yes',
      );
    }
  });
};

liveStreamPreview();
